<template>
  <div class="FAQ">
    <div class="topbar">
      <v-icon size="30" @click="$router.push('/')" color="#000"
        >mdi-arrow-left</v-icon
      >

      <div class="head-title">{{ $t("常見問題") }}</div>
    </div>

    <div class="content">
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in questionList" :key="i">
          <v-expansion-panel-header> {{ item.ask }}</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5" style="text-align: left">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
// 引入i18n
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      questionList: [
        {
          ask: `Q. ${i18n.t("G18帳號可以用來做什麼？")}`,
          answer: `A. ${i18n.t(
            "帳號申請後，您即可使用該帳號遊玩所有於G18的遊戲。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("遺失快速登錄的帳號密碼時該怎麼辦？")}`,
          answer: `A. ${i18n.t(
            "快速登錄因屬較簡易的註冊及登錄方式，若是您遺失了快速登錄的帳號資訊，我們也將難以核對及找回該帳號的資料，還望見諒。也因此，我們強烈建議玩家於快速登錄註冊完畢後，截圖保存您的帳戶及密碼等相關資訊，以保全您的權益。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("玩G18遊戲需要付費嗎？")}`,
          answer: `A. ${i18n.t(
            "玩G18的遊戲基本上是不需要付費的；但遊戲內部分的道具，以及平台的通用點數將可能需要玩家儲值獲取。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("一直登不進去遊戲，該怎麼辦？")}`,
          answer: `A. ${i18n.t(
            "遊戲在維修時可能出現登不進去的情形，建議玩家先行確認您嘗試登錄的時段是否為該遊戲或平台的維修時段；若您登錄時段非維修時段，亦建議您先行嘗試以下方式:1.關閉遊戲後，再重新開啟遊戲。2.確認您網路狀況是否穩定。3.確認及清理您設備的暫存檔案。4.若是需要下載的遊戲，還請記下您的帳號密碼後，將遊戲移除及重新安裝遊戲。若是以上方式皆無法解決您問題，還請您點選「會員中心」裡的「聯繫客服」，填寫您的郵件及您無法開啟遊戲的相關資訊，我們將於收到後盡速為您確認及回覆。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("遊戲對應環境是？")}`,
          answer: `A. ${i18n.t(
            "G18有PC及手機版本的遊戲；而根據各遊戲的不同，對應的瀏覽器及手機系統版本亦不盡相同，建議玩家可以參考各遊戲頁面中的系統需求。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("可以使用的付款方式有什麼？")}`,
          answer: `A. ${i18n.t(
            "我們提供銀聯卡、支付寶、信用卡、超商代收、ATM、點數卡、網銀、Paypal……等多項支付方式，現有的付款方式您也可以於登錄G18後，於儲值頁面進行確認。此外，未來我們也會持續新增其他支付方式，使我們的用戶儲值更加便利，敬請期待！"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("購買道具或點數失敗了該怎麼辦？")}`,
          answer: `A. ${i18n.t(
            "玩家進行付款後到實際虛擬商品反映至您的帳戶，通常會有一小段時間差，建議玩家購買完成，隔數分鐘後再行刷新頁面；但若您等待1~2個工作天後仍未在您的帳戶中確認到道具或點數，還請您盡速聯繫客服並提供我們您的平台玩家ID、您於遊戲中的暱稱、以及扣款成功證明等相關資訊，我們將會為您再次進行更詳細的確認。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("出現同一筆款項被重複請款的情形。")}`,
          answer: `A. ${i18n.t(
            "若是出現同一筆款項被重複請款的情形，還請您盡速聯繫客服並提供我們您的平台玩家ID、您於遊戲中的暱稱、以及扣款成功證明等相關資訊，我們將會為您再次進行更詳細的確認及返還多收的款項。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("可以退款嗎？")}`,
          answer: `A. ${i18n.t(
            "已購買的遊戲商品及點數皆是無法退款的，還望您見諒。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("如何儲值？")}`,
          answer: `A. ${i18n.t(
            "還請玩家先登入帳號後，點選「會員中心」-「點數查詢」中「充值」按鈕，進行您希望儲值的金額及支付方式等選擇。另外，依據您選擇支付方式的不同，後續的支付流程亦不盡相同。在您完成支付方式的選擇後，亦請您依照各支付方式的指定流程完成整個付款流程"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("如何使用點數？")}`,
          answer: `A. ${i18n.t(
            "您在G18充值點數成功後，可以在「會員中心」-「點數查詢」中查看點數餘額。擁有點數後，您可以在G18平台的任意遊戲中進行道具購買，遊戲會直接扣除相應的點數。1點數=1人民幣。"
          )}`,
        },
        {
          ask: `Q. ${i18n.t("如何解封？")}`,
          answer: `A. ${i18n.t(
            "如果您被封禁了，無法正常登錄遊戲，請在「聯繫我們」中複製客服郵箱，給客服發送郵件，填寫您的帳號信息聯繫客服進行處理。"
          )}`,
        },
      ],
    };
  },
  created() {
    var appToken = this.getQueryString("appToken");
    if (appToken) {
      this.$get("/api/account/getNewToken", {
        token: appToken,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
      });
    }
  },
  methods: {
    getQueryString(name) {
      var value;
      var url = window.location.href;
      var pairs = url.split("?");
      if (pairs.length < 2) return value;

      pairs = pairs[pairs.length - 1].split("&");

      if (pairs.length < 1) return value;
      pairs.forEach((element) => {
        var pair = element.split("=");
        var key = pair[0];

        if (key.toLowerCase() == name.toLowerCase()) {
          value = pair[1];
          return;
        }
      });
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.FAQ {
  padding-top: 46px;
  .topbar {
    background-color: #fff;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #000;
    }
  }
  .content {
    padding: 10px 24px;
    .v-expansion-panel::v-deep {
      .v-expansion-panel-header {
        margin: 3px 0;
        font-size: 18px;
        background: #a7a6a6;
        color: #fff;
        .v-icon {
          color: #fff;
        }
      }
      .v-expansion-panel-content {
        font-size: 18px;
      }
    }
  }
}
</style>